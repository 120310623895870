import type { NextPage } from 'next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAuth } from 'src/util/use-auth';
import FullScreenLoader from 'src/components/Loader';

const Home: NextPage = () => {
  const { push } = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    push(user ? '/dashboard' : '/login').catch((error) => {
      const title = error instanceof Error ? error.message : (error as string);
    });
  }, [push, user]);

  return <FullScreenLoader />;
};

export default Home;
